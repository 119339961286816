<template>
  <modal-card
    :title="step.title"
    :cancel-text="'Close'"
    :processing="isProcessing"
  >
    <keep-alive>
      <component :is="step.component" v-bind="step.props" v-on="step.events" />
    </keep-alive>
    <button
      slot="footer"
      :disabled="!step.isValid() || isProcessing"
      :class="{ 'is-loading': isProcessing }"
      type="submit"
      class="button is-success"
      @click="step.cta"
    >
      {{ step.ctaLabel }}
    </button>
  </modal-card>
</template>

<script>
export default {
  name: "OneOffInvoiceModal",
  props: {
    userId: {
      required: true,
      type: String
    },
    siteId: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentStep: 0,
      isProcessing: false,
      cart: {
        paymentMethodId: null,
        invoice: null
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    step() {
      return this.steps[this.currentStep];
    },
    steps() {
      const vm = this;
      return [
        {
          title: "Select payment method",
          component: () => import("@shared/cart/_selectPaymentMethod"),
          props: {
            userId: this.userId
          },
          events: {
            cartdata: ({ paymentMethod }) => {
              vm.cart.paymentMethodId = this.$_.get(paymentMethod, "id");
            }
          },
          isValid: () => !this.$_.isEmpty(this.cart.paymentMethodId),
          cta: () => vm.currentStep++,
          ctaLabel: "Continue"
        },
        {
          title: "Create manual invoice",
          component: () => import("@shared/invoices/_createManualInvoice.vue"),
          props: {
            userId: this.userId,
            siteId: this.siteId,
            currency: this.user.currency || "gbp",
            disabled: this.isProcessing
          },
          events: {
            invoice: invoice => {
              vm.cart.invoice = invoice;
            }
          },
          isValid: () =>
            this.cart.invoice &&
            this.cart.invoice.items.length > 0 &&
            this.cart.invoice.currency &&
            this.cart.invoice.userId &&
            // All items have price
            this.cart.invoice.items.filter(
              i => !this.$_.isNumber(i.price) || i.price <= 0
            ).length === 0 &&
            // All items have description
            this.cart.invoice.items.filter(i => this.$_.isEmpty(i.description))
              .length === 0,
          cta: () => {
            vm.isProcessing = true;
            this.$store
              .dispatch("billing/createManualInvoice", {
                siteId: this.siteId,
                paymentMethodId: this.cart.paymentMethodId,
                invoice: this.cart.invoice
              })
              .then(message => {
                this.$emit("close");
                this.$toast.open({
                  message: message || "Invoice created"
                });
              })
              .catch(message => {
                this.$toast.open({
                  message: message || "Error creating invoice",
                  type: "is-danger"
                });
              })
              .finally(() => {
                vm.isProcessing = false;
              });
          },
          ctaLabel: "Create invoice & pay"
        }
      ];
    }
  },
  created() {
    this.$store.dispatch("user/observeUser", {
      userId: this.userId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", {
      userId: this.userId
    });
  }
};
</script>
